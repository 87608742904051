$icon-transition-time: 1.0s;
$envelope-color: #f39c12;
$twitter-color: #00aced;
$instagram-color: #375989;
$github-color: #343434;
$linkedin-color: #0073a4;
$facebook-color: #3d5b99;
$google-color: #e64a41;
$pinterest-color: #bd081c;
$medium-color: #00AB6C;
$codepen-color: black;
$rss-color: #ff6600;
$discord-color: #7289DA;

@mixin social-media-icon($color, $transition){
  -webkit-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
  &:hover{
    color: $color;
  }
}

.fa-envelope {
  padding: 5px;
  // @include social-media-icon($envelope-color, $icon-transition-time);
}

.fa-discord {
  padding: 5px;
  // @include social-media-icon($discord-color, $icon-transition-time);
}

.fa-twitter {
  padding: 5px;
  // @include social-media-icon($twitter-color, $icon-transition-time);
}

.fa-instagram {
  padding: 5px;
  // @include social-media-icon($instagram-color, $icon-transition-time);
}

.fa-github {
  padding: 5px;
  // @include social-media-icon($github-color, $icon-transition-time);
}

.fa-linkedin {
  padding: 5px;
  // @include social-media-icon($linkedin-color, $icon-transition-time);
}

.fa-facebook {
  padding: 5px;
  // @include social-media-icon($facebook-color, $icon-transition-time);
}

.fa-google-plus {
  padding: 5px;
  // @include social-media-icon($google-color, $icon-transition-time);
}

.fa-pinterest {
  padding: 5px;
  @include social-media-icon($pinterest-color, $icon-transition-time);
}

.fa-medium {
  padding: 5px;
  @include social-media-icon($medium-color, $icon-transition-time);
}

.fa-codepen {
  padding: 5px;
  // @include social-media-icon($codepen-color, $icon-transition-time);
}

.fa-rss-square {
  padding: 5px;
  // @include social-media-icon($rss-color, $icon-transition-time);
}
