.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $base-color;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.sidenav-header {
  margin-bottom: 15px;
}
.sidenav-header img {
  margin: 0;
  position: absolute;
  top: 16px;
  left: 45px;
  width: 110px;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: $brand-color;
  display: block;
  transition: 0.3s;
  margin-bottom: 15px;
}
.sidenav a:hover {
  color: #0e1111;
  background-color: $brand-color;
}
.sidenav .closebtn {
  position: absolute;
  top: 1px;
  right: 25px;
  font-size: 50px;
  opacity: 0;
}
.sidenav .closebtn:hover {
  color: $brand-color;
  background-color: transparent;
}
.sidenav .menu-link.social-link {
  padding-left: 28px;
}
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}