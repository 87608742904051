%post-title {
  bottom: 0;
  margin: 0;
  padding: 10px;
  position: absolute;
}
.featured-posts {
  height: 400px;
  margin: 5px 10px 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.featured-posts h2 {
  @extend %post-title;
}
.featured-posts h2.even {
  @extend %post-title;
  right: 0;
}
.featured-posts h2 span {
  display: inline-block;
  color: white;
  // font: bold 24px/45px Helvetica, Sans-Serif;
  font: bold 24px/45px Montserrat, Sans-Serif;
  letter-spacing: -1px;
  background: rgb(0, 0, 0); /* fallback color */
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
}
.featured-posts span a {
  color: white;
}
.featured-posts h1 {
  margin: 10px;
}


/*
*/
@media (max-width: $container-width) {
  .featured-posts {
    height: 35vw;
    margin: 5px 10px 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  }
  .featured-posts h2 {
    padding-left: 30px;
  }
  .featured-posts h2 span {
    font: bold 20px/41px Helvetica, Sans-Serif;
    letter-spacing: 0px;
  }
}

@media (max-width: $tablet-width) {
  .featured-posts {
    height: 35vw;
    margin: 5px 10px 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  }
  .featured-posts h2 {
    padding-left: 10px;
  }
  .featured-posts h2 span {
    font: bold 15px/26px Helvetica, Sans-Serif;
    letter-spacing: 0px;
  }
}
/*
*/

/*
  Pagination
*/

.pagination {
  text-align: center;
}
.pagination a {
  text-decoration: none;
}
.pagination-button {
  color: #999;
  border: 1px solid #e0e0e0;
  padding: 15px 45px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}
.pagination-active {
  color: black;
}
.pagination-active:hover {
  background-color: #e0e0e0;
}
