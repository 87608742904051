.page-content {
  // width: 600px;
  width: 765px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.page-content h1 {
  font-family: "Montserrat",sans-serif;
}
.post-date {
  display: block;
  margin-bottom: 1rem;
  color: #9a9a9a;
  font-size:0.8rem;
  font-family: $title-font-family;
}
.featured-image img {
  margin: 5px auto 25px;
  display: block;
  // max-width: 600px;
  max-width: 765px;
}
@media (max-width: $container-width) {
  .page-content {
    width: 60vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .featured-image img {
    margin: 5px auto 25px;
    display: block;
    max-width: 60vw;
  }
}
.post-share {
  padding-bottom: 1rem;
}
.sharing-icons a {
  padding-right: 10px;
}

/*
  Related Posts
*/
.related-flex {
  // padding-bottom: 1rem;
  margin-top: 25px;
}
.related-posts-flex {
  display: flex;
  flex-direction: column;
}
.related-post-flex {
  align-self: flex-start;
  padding-bottom: 1.5rem;
}
.related-thumbnail-flex img {
  width: 100%;
}
.related-title-flex {
  float: right;
  padding-bottom: .5rem;
  font-family: $title-font-family;
}
// .related {
//   padding-bottom: 1rem;
//   margin-top: 50px;
//   // text-align: right;
// }
// .related h2 {
//   font-family: $title-font-family;
//   text-align: left;
// }
// .related ul, ol, dl {
//   margin: 0px;
// }
// .related-posts {
//   // padding: 10px 0px 10px;
//   list-style: none;
//   display: inline-block;
// }
// .related-thumbnail {
//   position: relative;
//   // width: 600px;
//   width: 765px;
//   // height: 200px;
//   height: 250px;
//   overflow: hidden;
// }
// .related img {
//   position: absolute;
//   // left: 50%;
//   left: 39%;
//   top: 50%;
//   height: auto;
//   // width: 600px;
//   width: 765px;
//   -webkit-transform: translate(-50%,-50%);
//       -ms-transform: translate(-50%,-50%);
//           transform: translate(-50%,-50%);
// }
// .related-title {
//   float: right;
//   padding-bottom: .5rem;
//   font-family: $title-font-family;
// }

// @media (max-width: $container-width) {
//   .related-thumbnail{
//     width: 60vw;
//     height: 200px;
//   }
//   .related img {
//     max-width: 60vw;
//     left: 50%;
//   }
// }

@media (max-width: $tablet-width) {
  .page-content {
    width: 95vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .featured-image img {
    margin: 5px auto 25px;
    display: block;
    max-width: 95vw;
  }
  // .related-thumbnail{
  //   width: 95vw;
  //   height: 100px;
  // }
  // .related img {
  //   max-width: 95vw;
  //   top: 23%;
  //   left: 50%;
  // }
}
