.page-title {
  padding: 0px 10px 10px;
}
.misc-content {
  color: $brand-color;
  // max-width: 600px;
  max-width: 765px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.steam-badge {
  max-width: 100%;
}
