/*!
 * Age verification CSS to go with the JS that can be found at (https://github.com/Herudea/age-verification)
 */

#modal_screen {
	position: fixed;
	top:0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1100;
	background-color: rgba(0,0,0,.8);
}

#modal_screen.nope {
	background-color: $base-color;
}

#modal_content {
	position: fixed;
	z-index: 1101;
	margin: 0 auto;
	background-color:#fff;
	border:4px solid #444;
	border-radius: 100%;
	font-size: 12px;
	width:290px; /* if you adjust it from the original 290px, adjust the difference in the width of #modal_content nav too */
	height: 290px; /* if you adjust it from the original 290px, adjust the difference in the width of #modal_content nav too */

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;

	background-position: center 2em;
	background-repeat: no-repeat;
	background-size: 90px auto;
	// background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOC4xLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4yIiBiYXNlUHJvZmlsZT0idGlueSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiDQoJIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTAwcHgiIGhlaWdodD0iMTAwcHgiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxyZWN0IHg9IjY2IiB5PSI0NSIgZmlsbD0iIzMzMzMzMyIgd2lkdGg9IjUiIGhlaWdodD0iOCIvPg0KPHJlY3QgeD0iMzkiIHk9IjQ1IiBmaWxsPSIjMzMzMzMzIiB3aWR0aD0iNSIgaGVpZ2h0PSI4Ii8+DQo8cGF0aCBmaWxsPSIjMzMzMzMzIiBkPSJNMTAwLDEyYzAtNi42LTUuNC0xMi0xMi0xMkgxMkM1LjQsMCwwLDUuNCwwLDEydjc2YzAsNi42LDUuNCwxMiwxMiwxMmg3NmM2LjYsMCwxMi01LjQsMTItMTJWMTJ6IE0zMiw1NGgtOQ0KCVY0NGgzdjloNlY1NHogTTQ3LDUzaC0ydjFoLTd2LTFoLTJ2LThoMnYtMWg3djFoMlY1M3ogTTYwLDQ1aC03djhoNHYtM2gtMXYtMmg0djVoLTF2MWgtOHYtMWgtMXYtOGgxdi0xaDlWNDV6IE03NCw1M2gtMnYxaC03di0xDQoJaC0ydi04aDJ2LTFoN3YxaDJWNTN6Ii8+DQo8L3N2Zz4NCg==);
	background-image: url(/assets/img/jimmy18.png);
}

#modal_content .content_wrapper {
	padding-top: 8em;
	margin:3em auto 0;
	text-align: center;
}

#modal_content h2 {
	margin: 0 0 0 0;
	font:normal 15px/1.3em 'Roboto', sans-serif;;
}

#modal_content p {
	max-width: 75%;
	margin: 1.2em auto 0;
	font:8px/1.5em 'Roboto', sans-serif;;
}

#modal_content nav {font-size: 16px; display:table; width: 282px;}
#modal_content nav ul {display:table-row; width: 100%; margin:0; padding:0;}
#modal_content nav li {display: table-cell; text-align: right; width: auto;}

#modal_content nav:after {
	content: "";
	display: block;
	clear: both;
}

#modal_content nav small {
	display: block;
	text-align: center;
	color: #888;
	margin: 1em 0 0;
}

#modal_content nav a.av_btn {
	display:block;
	text-align:center;
	width: 65px;
	border-radius: 23px;
	margin:1em auto 0;
	text-decoration: none;
	padding:1em;
	color: #fff;
	/* font:normal .7em/1em 'AmericanTypwrterITCW01- 731031'; */
	text-transform: none;
	letter-spacing: 0;
}

#modal_content nav .av_go {
  background-color: #7fbf4d;
  // background-color: #938d95;
}

#modal_content nav .av_no {
	background-color: #dc3545;
	// background-color: #938d95;
}

#modal_content nav .av_btn:hover {
	background-color: $base-color;
}

#modal_content #modal_regret_wrapper nav a.av_btn {
	margin-top:0;
	width:130px;
	font-size: 0.8em;
}

@media (min-width: 688px) {
	#modal_content {
		top: 10em;
		top: 30vh;
		left: 30vw;
		width: 90%;
		max-width: 500px;
		height:500px;
		padding:3em;
		border-radius: 100%;
		background-position: center 3em;
		background-size: 160px auto;
		font-size: 16px;
	}

	#modal_content .content_wrapper {
		padding-top: 150px;
		margin:3em auto 0;
		text-align: center;
	}

	#modal_content h2 {
		margin: 0 0 0 0;
		font-size: 1.875em;
	}

	#modal_content p {
		font-size: 1em;
		line-height: 1.9em;
	}

	#modal_content nav {width: 396px;}

	#modal_content nav a.av_btn {
		padding:1em 2.5em;
		margin:1.6em auto 0;
		font-size: 1em;
	}
}
