code,
pre {
  font-family: $code-font-family
}
code {
  padding: .25em .5em;
  font-size: .8rem;
  background-color: #f5f5f5;
  border-radius: 3px;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: .8rem;
  line-height: 1.4;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  background-color: $base-color;
}
pre code {
  padding: 0;
  font-size: 100%;
  color: inherit;
  background-color: transparent;
}

/* Pygments via Jekyll */
.highlight {
  margin-bottom: 1rem;
  border-radius: 4px;
}
.highlight pre {
  margin-bottom: 0;
}

/* Gist via GitHub Pages */
.gist .gist-file {
  font-family: $code-font-family !important;
}
.gist .markdown-body {
  padding: 15px;
}
.gist pre {
  padding: 0;
  background-color: transparent;
}
.gist .gist-file .gist-data {
  font-size: .8rem !important;
  line-height: 1.4;
}
.gist code {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}
