/*
  Reset some basic elements
*/

body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/*
  Basic styling
*/

html {
  background-color: $base-color;
  scroll-behavior: smooth;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: bold;
  line-height: 1.25;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2rem;
}
h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
}
h3 {
  margin-top: 1.5rem;
  font-size: 1.25rem;
}
h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem;
}

/* Body */
body {
  font-family: $base-font-family;
}
body img {
    align: middle;
    margin: 5px auto auto auto;
    display: block;
    max-width: 600px;
}

p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  line-height: 1.65;
  font-family: $body-font-family;
}

/* Lists */
ul, ol, dl {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
}

ol li {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
}

li {
  margin-bottom: 1rem;
}

dt {
  font-weight: bold;
}
dd {
  margin-bottom: .5rem;
}

/*
  Links
*/

a {
  color: $brand-color;
  text-decoration: none;
}

/*
  Blockquote
*/

blockquote {
  margin: 10px 20px 10px;
  padding: 0px 15px;
  border-left: 0.25em solid #ccc;
  color: #999;
  line-height: 1.5;
}

/**
 * Tables
 */
table {
    border-collapse: collapse;
    margin-bottom: 30px;
    width: 100%;
}

table, th, td {
    border: 1px solid black;
}
th, td {
    padding: 15px;
    text-align: left;
}

textarea {
  resize: none;
}