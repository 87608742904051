.about-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
  align-items: center;
}
.about-container.reverse {
  flex-direction: row-reverse;
}
.about-container > img {
  margin: 0;
  // width: 230px;
  width: 300px;
}
.about-container > img.symbol {
  width: 115px;
}
.about-container > .general {
  
}
.about-container > .bio {
  flex-basis: 100%;
}
@media (max-width: $container-width) {
  .about-container > img {
    margin: 0;
    width: 230px;
  }
}
@media (max-width: $phone-width) {
  .about-container > img.symbol {
    width: 85px;
  }
}