.form-title {
	text-align: center;
	margin-bottom: 15px;
}
.flex-form {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	padding: 10px;
}
.flex-form-container {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	-webkit-flex-direction: row;
	padding-bottom: 15px;
}
.flex-form-label {
	width: 130px;
	font-weight: bold;
	text-align: left;
	margin-right: 5px;
}
.flex-form-input {
	flex: 1;
	-webkit-flex: 1;
	border: 0.1em solid $brand-color;
	border-radius: 0.12em;
	color: $base-color;
	font-family: $title-font-family;
	padding: 3px;
}
.form-control {
	flex: 1;
	-webkit-flex: 1;
	border: 0.1em solid $brand-color;
	border-radius: 0.12em;
	color: $base-color;
	font-family: $title-font-family;
}
textarea.form-control {
	height: 200px;
	font-size: smaller;
	padding: 3px;
}
.flex-form-btn {
	width: 150px;
	height: 35px;
	max-width: 200px;
	align-self: center;
	padding: 0.35em 1.2em;
	border: 0.1em solid $brand-color;
	margin: 0 0.3em 0.3em 0;
	border-radius: 0.12em;
	box-sizing: border-box;
	text-decoration:none;
	color: $base-color;
	text-align: center;
	font-family: $title-font-family;
	font-size: smaller;
	transition: all 0.2s;
	-webkit-appearance:none;
	background: $brand-color;
}
.flex-form-btn:hover:not([disabled]) {
	color:$base-color;
	background-color:$brand-color;
}
.flex-form-btn:disabled {
	color: rgba(0, 0, 0, 0.247);
}
@media all and (max-width:30em) {
	.flex-form-btn {
		display:block;
		margin:0.4em auto;
	}
}
@media (max-width: $phone-width) {
  .flex-form-container {
    flex-direction: column;
		-webkit-flex-direction: column;
	}

	.textarea-resize {
		height: 200px;
	}
}